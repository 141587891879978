import { difference } from 'es-toolkit';

// TODO: 주석 처리한 항목들의 노출은 필요없는지 더블 체크
// https://daangn.slack.com/archives/C024SRLUC31/p1732859606766479?thread_ts=1730977637.683739&cid=C024SRLUC31
export const optionOrderPriorities = [
  'options.leatherSeat.leatherSeat',
  'options.powerSeat.powerSeat',
  'options.heatedSeat.heatedSeat',
  'options.ventilatedSeat.ventilatedSeat',
  'options.indoorLight.indoorLight',
  'options.autoFoldSideMirror.autoFoldSideMirror',
  'options.sunroof.sunroof',
  'options.panoramicSunroof.panoramicSunroof',
  'options.headlamp.headlamp',
  'options.autoTrunk.autoTrunk',
  'options.camera.rear',
  'options.camera.around',
  'options.aeb.aeb',
  'options.ldws.ldws',
  'options.bsd.bsd',
  'options.tpms.tpms',
  'options.parkingSensor.front',
  'options.parkingSensor.rear',
  'options.smartKey.smartKey',
  // 'options.climateControlSystem.airConditioner',
  'options.climateControlSystem.autoAirConditioner',
  // 'options.climateControlSystem.heater',
  // 'options.climateControlSystem.blowerMotor',
  // 'options.climateControlSystem.blowerDirectionController',
  // 'options.avSystem.radio',
  'options.avSystem.monitor',
  'options.navigation.navigation',
  // 'options.navigation.touchPanel',
  // 'options.powerWindow.powerWindow',
  // 'options.wiper.wiper',
  'options.autoWiper.autoWiper',
  'options.hud.hud',
  'options.steeringHandle.electricalSteeringHandle',
  'options.heatedSteeringWheel.heatedSteeringWheel',
  'options.bluetooth.bluetooth',
  'options.cruiseControl.cruiseControl',
  'options.epb.epb',
] as const;

export const dealerNonVisibleOptions = [
  'options.aeb.aeb',
  'options.ldws.ldws',
  'options.bsd.bsd',
  'options.tpms.tpms',
  'options.parkingSensor.front',
  'options.parkingSensor.rear',
  'options.bluetooth.bluetooth',
  'options.cruiseControl.cruiseControl',
  'options.epb.epb',
] as const;

export const dealerVisibleOptions = difference(optionOrderPriorities, dealerNonVisibleOptions);

export const clientVisibleOptions = [
  'options.leatherSeat.leatherSeat',
  'options.heatedSeat.heatedSeat',
  'options.ventilatedSeat.ventilatedSeat',
  'options.heatedSteeringWheel.heatedSteeringWheel',
  'options.navigation.navigation',
  'options.cruiseControl.cruiseControl',
  'options.parkingSensor.rear',
  'options.camera.rear',
  'options.bsd.bsd',
  'options.aeb.aeb',
  'options.camera.around',
  'options.ldws.ldws',
] as const;
