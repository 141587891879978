import type { MechanicReportCategory, ReportItemState } from '@daangn/car-types';

import { pick } from 'es-toolkit';

export const pickMechanicReport = <T extends Record<string, any>>(
  report: T,
  categories: MechanicReportCategory[]
) => {
  const result = pick(report, categories);
  return result as {
    [category: string]: {
      [group: string]: { [part: string]: ReportItemState | undefined } | undefined;
    };
  };
};
