import type { ActivityName } from '@/stackflow/Activity';

import { articleLoader } from '@/pages/Article/loader';
import { auctionTradeProgressLoader } from '@/pages/AuctionTradeProgress/loader';
import { mainLoader } from '@/pages/Main/loader';
import { myInspectionsLoader } from '@/pages/MyInspections/loader';

export const activityLoader: Partial<Record<ActivityName, any>> = {
  main: mainLoader,
  article: articleLoader,
  auction_trade_progress: auctionTradeProgressLoader,
  my_inspections: myInspectionsLoader,
};
