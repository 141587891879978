import { z } from 'zod';

import { bankItems } from './BankItems';

export const BankCodeSchema = z
  .string()
  .length(3, { message: '은행을 선택해 주세요.' })
  .refine((v) => bankItems.some((bank) => bank.value === v), {
    message: '올바른 은행을 선택해 주세요.',
  });
