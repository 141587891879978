import type { MechanicReportPath, ReportItemState, ReportItemStatePath } from '@daangn/car-types';

import { hasPartIssue } from '@daangn/car-types';
import { nonNullish } from '@daangn/car-utils/typeGuard';
import { objectEntries } from '@toss/utils';

type ReportItemType = 'empty' | 'none' | 'selected' | 'selectedWithInformation';
const initializeResult = (): Record<'total' | ReportItemType, number> => ({
  empty: 0,
  none: 0,
  selected: 0,
  selectedWithInformation: 0,
  total: 0,
});

export const getReportPublicItemStateType = (
  state: ReportItemState,
  fullPath: ReportItemStatePath
): ReportItemType => {
  if (state.selections.includes('None')) {
    return 'none';
  }
  if (!hasPartIssue(state, fullPath)) {
    return 'empty';
  }
  if (state.comment || state.imgs?.length) {
    return 'selectedWithInformation';
  }
  return 'selected';
};

export const getPartsPublicIssueCount = (
  partEntries: [ReportItemStatePath, ReportItemState | undefined][]
) => {
  const result = initializeResult();
  partEntries
    .filter((entry): entry is [ReportItemStatePath, ReportItemState] => nonNullish(entry.at(1)))
    .forEach(([partPath, part]) => {
      result[getReportPublicItemStateType(part, partPath)]++;
      result.total++;
    });

  return result;
};

export const getGroupPublicIssueCount = (
  group?: Record<string, ReportItemState | undefined>,
  categoryGroupPath?: MechanicReportPath
) => {
  if (!group) {
    return initializeResult();
  }
  return getPartsPublicIssueCount(
    objectEntries(group).map(([partPath, part]) => [
      `${categoryGroupPath}.${partPath}` as ReportItemStatePath,
      part,
    ])
  );
};

export const getCategoryPublicIssueCount = (
  category: Record<string, Record<string, ReportItemState | undefined> | undefined>,
  categoryPath: MechanicReportPath
) => {
  const result = initializeResult();

  if (!category) {
    return result;
  }

  objectEntries(category).forEach(([groupPath, group]) => {
    const categoryGroupPath = `${categoryPath}.${groupPath}` as MechanicReportPath;
    const groupResult = getGroupPublicIssueCount(group, categoryGroupPath);
    Object.keys(result).forEach((key) => {
      result[key as ReportItemType] += groupResult[key as ReportItemType];
    });
  });

  return result;
};
