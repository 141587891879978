import { type MechanicReportCategory, pickMechanicReport } from '@daangn/car-types';

export const fixableCategories: MechanicReportCategory[] = [
  'engine',
  'autoTransmission',
  'manualTransmission',
  'powertrain',
  'steering',
  'fuelSystem',
  'paint',
  'exterior',
  'interiorCondition',
  'brakingSystem',
  'electricalSystem',
  'highVoltageElectricalSystem',
  'options',
];

export const isFixableCategory = (v: string) => {
  return fixableCategories.some((category) => v.startsWith(category));
};

export const getMechanicReportFixable = <T extends Record<string, any>>(report: T) => {
  return pickMechanicReport(report, fixableCategories);
};
