import { graphql, loadQuery } from 'react-relay';

import { loader_MyInspectionsQuery } from '@/__generated__/loader_MyInspectionsQuery.graphql';
import environment from '@/relay/environment';

export const MyInspectionsQuery = graphql`
  query loader_MyInspectionsQuery {
    upcomingInspections {
      ...InspectionCardFragment
      fixedDate
      id
    }
    ...MyInspections_query
    inspectionRequestItems {
      ...InspectionRequestCard_InspectionRequestItem
      inspection {
        id
      }
    }
  }
`;

export const myInspectionsLoader = async () => {
  return {
    preloadedQuery: loadQuery<loader_MyInspectionsQuery>(environment, MyInspectionsQuery, {}),
  };
};
