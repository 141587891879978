import type { MechanicReportPath } from '@daangn/car-types';

import { objectKeys } from '@toss/utils';
import { match } from 'ts-pattern';

// 공백은 화면상에서 쓰는 곳이 없어요. 앞뒤좌우

export const reportTexts: Record<MechanicReportPath, string> = {
  accidentHistory: '사고 이력',
  'accidentHistory.externalPanel': '',
  'accidentHistory.externalPanel.leftFender': '',
  'accidentHistory.externalPanel.rightFender': '',
  'accidentHistory.externalPanel.leftFrontDoor': '',
  'accidentHistory.externalPanel.rightFrontDoor': '',
  'accidentHistory.externalPanel.leftRearDoor': '',
  'accidentHistory.externalPanel.rightRearDoor': '',
  'accidentHistory.externalPanel.leftQuarterPanel': '',
  'accidentHistory.externalPanel.rightQuarterPanel': '',
  'accidentHistory.externalPanel.hood': '',
  'accidentHistory.externalPanel.trunkLid': '',
  'accidentHistory.externalPanel.roofPanel': '',
  'accidentHistory.externalPanel.radiatorSupport': '',
  'accidentHistory.externalPanel.leftSideSealPanel': '',
  'accidentHistory.externalPanel.rightSideSealPanel': '',
  'accidentHistory.mainFrame': '',
  'accidentHistory.mainFrame.frontPanel': '',
  'accidentHistory.mainFrame.leftInsidePanel': '',
  'accidentHistory.mainFrame.rightInsidePanel': '',
  'accidentHistory.mainFrame.leftFrontWheelHouse': '',
  'accidentHistory.mainFrame.rightFrontWheelHouse': '',
  'accidentHistory.mainFrame.leftRearWheelHouse': '',
  'accidentHistory.mainFrame.rightRearWheelHouse': '',
  'accidentHistory.mainFrame.leftFrontSideMember': '',
  'accidentHistory.mainFrame.rightFrontSideMember': '',
  'accidentHistory.mainFrame.leftRearSideMember': '',
  'accidentHistory.mainFrame.rightRearSideMember': '',
  'accidentHistory.mainFrame.crossMember': '',
  'accidentHistory.mainFrame.dashPanel': '',
  'accidentHistory.mainFrame.floorPanel': '',
  'accidentHistory.mainFrame.packageTray': '',
  'accidentHistory.mainFrame.rearPanel': '',
  'accidentHistory.mainFrame.leftPillarPanelA': '',
  'accidentHistory.mainFrame.leftPillarPanelB': '',
  'accidentHistory.mainFrame.leftPillarPanelC': '',
  'accidentHistory.mainFrame.leftPillarPanelD': '',
  'accidentHistory.mainFrame.rightPillarPanelA': '',
  'accidentHistory.mainFrame.rightPillarPanelB': '',
  'accidentHistory.mainFrame.rightPillarPanelC': '',
  'accidentHistory.mainFrame.rightPillarPanelD': '',
  'accidentHistory.mainFrame.trunkFloor': '',
  baseInfo: '기본 정보',
  'baseInfo.driveDistance': '주행거리',
  'baseInfo.gearType': '변속기',
  'baseInfo.powerSteeringType': '조향 장치',
  'baseInfo.powertrainLayout': '구동 방식',
  'baseInfo.tuningHistory': '튜닝 이력',
  'baseInfo.fuelType': '연료 방식',
  tire: '타이어',
  'tire.productionDate': '제조 일자',
  'tire.productionDate.front': '앞',
  'tire.tireTreadDepth': '트레드',
  'tire.tireTreadDepth.frontDriverSeat': '앞-운전석',
  'tire.tireTreadDepth.rearDriverSeat': '뒤-운전석',
  'tire.tireTreadDepth.frontPassengerSeat': '앞-조수석',
  'tire.tireTreadDepth.rearPassengerSeat': '뒤-조수석',
  'tire.tireCondition': '타이어 상태',
  'tire.tireCondition.front': '앞',
  'tire.tireCondition.rear': '뒤',
  'tire.brakePadWear': '브레이크 패드',
  'tire.brakePadWear.front': '앞',
  'tire.brakePadWear.rear': '뒤',
  engine: '원동기',
  'engine.operation': '작동 상태',
  'engine.operation.ignition': '시동',
  'engine.operation.idle': '공회전',
  'engine.operation.acceleration': '가속',
  'engine.engineOil': '엔진 오일',
  'engine.engineOil.flow': '유량',
  'engine.engineOil.condition': '상태',
  'engine.oilLeak': '오일 누유',
  'engine.oilLeak.rockerArmCover': '로커암 커버',
  'engine.oilLeak.frontCover': '프론트 커버 (케이스)',
  'engine.oilLeak.cylinderHead': '실린더 헤드 (가스켓)',
  'engine.oilLeak.engineBlock': '엔진 블럭',
  'engine.oilLeak.oilPan': '오일 팬',
  'engine.coolant': '냉각수',
  'engine.coolant.condition': '상태',
  'engine.coolant.level': '수량',
  'engine.coolantLeak': '냉각수 누수',
  'engine.coolantLeak.cylinderHead': '실린더 헤드 (가스켓)',
  'engine.coolantLeak.radiator': '라디에이터',
  'engine.coolantLeak.waterPump': '워터 펌프 (순환펌프)',
  'engine.highPressurePump': '고압 펌프 (커먼레일)',
  'engine.highPressurePump.highPressurePump': '고압 펌프 (커먼레일)',
  autoTransmission: '자동 변속기',
  'autoTransmission.operation': '작동 상태',
  'autoTransmission.operation.PRND': 'P -> R -> N -> D',
  'autoTransmission.operation.DNRP': 'D -> N -> R -> P',
  'autoTransmission.missionOil': '미션 오일',
  'autoTransmission.missionOil.level': '유량',
  'autoTransmission.missionOil.condition': '상태',
  'autoTransmission.oilLeak': '오일 누유',
  'autoTransmission.oilLeak.oilPan': '오일 팬',
  'autoTransmission.oilLeak.missionCase': '미션 케이스',
  'autoTransmission.oilLeak.pump': '펌프',
  manualTransmission: '수동 변속기',
  'manualTransmission.gearShift': '기어 변속장치',
  'manualTransmission.gearShift.gearShift': '기어 변속장치',
  'manualTransmission.oilLeak': '오일 누유',
  'manualTransmission.oilLeak.oilLeak': '오일 누유',
  'manualTransmission.operation': '작동 상태',
  'manualTransmission.operation.operation': '작동 상태',
  powertrain: '동력 전달',
  'powertrain.driveShaft': '드라이브 샤프트',
  'powertrain.driveShaft.rubberBootDamage': '고무 부트',
  'powertrain.driveShaft.greaseLeak': '구리스 누유',
  'powertrain.differential': '디퍼런셜',
  'powertrain.differential.caseLeak': '케이스 누유',
  'powertrain.differential.oilSealLeak': '오일실 누유',
  'powertrain.transferCase': '트랜스퍼 케이스',
  'powertrain.transferCase.caseLeak': '케이스 누유',
  'powertrain.transferCase.oilSealLeak': '오일실 누유',
  'powertrain.propellerShaft': '프로펠러 샤프트',
  'powertrain.propellerShaft.shaftLooseness': '유격',
  'powertrain.propellerShaft.discDamage': '디스크 손상',
  'powertrain.propellerShaft.bearingDamage': '베어링 손상',
  'powertrain.clutchAssembly': '클러치 어셈블리',
  'powertrain.clutchAssembly.clutchAssembly': '클러치 어셈블리',
  steering: '조향장치',
  'steering.operation': '작동 상태',
  'steering.operation.steeringLooseness': '작동시 유격',
  'steering.powerOil': '파워 오일',
  'steering.powerOil.level': '유량',
  'steering.oilLeak': '오일 누유',
  'steering.oilLeak.pump': '파워 스티어링 펌프',
  'steering.oilLeak.gearBox': '파워 스티어링 기어박스',
  'steering.oilLeak.highPressureHose': '고압호스',
  'steering.tieRodAndBallJoint': '타이로드엔드 및 볼조인트',
  'steering.tieRodAndBallJoint.tieRodAndBallJoint': '타이로드엔드 및 볼조인트',
  brakingSystem: '제동장치 및 서스펜션',
  'brakingSystem.operation': '작동 상태',
  'brakingSystem.operation.vacuumBrakeBooster': '진공배력장치',
  'brakingSystem.brakeFluid': '브레이크액 상태',
  'brakingSystem.brakeFluid.brakeFluid': '브레이크액 상태',
  'brakingSystem.oilLeak': '오일 누유',
  'brakingSystem.oilLeak.masterCylinder': '마스터실린더',
  'brakingSystem.oilLeak.caliper': '캘리퍼',
  'brakingSystem.suspension': '서스펜션',
  'brakingSystem.suspension.suspension': '서스펜션',
  electricalSystem: '전기장치',
  'electricalSystem.generator': '발전기',
  'electricalSystem.generator.outputVoltage': '출력전압',
  'electricalSystem.generator.noise': '작동 시 소음 (풀리포함)',
  'electricalSystem.starterMotor': '시동 모터',
  'electricalSystem.starterMotor.operation': '작동 상태',
  'electricalSystem.radiatorFanMotor': '라디에이터팬 모터',
  'electricalSystem.radiatorFanMotor.radiatorFanMotor': '라디에이터팬 모터',
  highVoltageElectricalSystem: '고전원 전기장치',
  'highVoltageElectricalSystem.batteryIsolation': '구동축전지 격리 상태',
  'highVoltageElectricalSystem.batteryIsolation.isolation': '구동축전지 격리 상태',
  'highVoltageElectricalSystem.chargingPortIsolation': '충전구 절연 상태',
  'highVoltageElectricalSystem.chargingPortIsolation.isolation': '충전구 절연 상태',
  'highVoltageElectricalSystem.wire': '고전원 전기배선 상태',
  'highVoltageElectricalSystem.wire.wire': '고전원 전기배선 상태',
  fuelSystem: '연료장치',
  'fuelSystem.fuelLeak': '연료 누출',
  'fuelSystem.fuelLeak.gasolineLeak': '가솔린',
  'fuelSystem.fuelLeak.dieselLeak': '디젤',
  'fuelSystem.fuelLeak.lpgLeak': 'LPG',
  paint: '도장 상태',
  'paint.paint': '전체 도색',
  'paint.paint.sameColor': '동일 색상',
  'paint.paint.differentColor': '색상 변경',
  'paint.paint.wrapping': '랩핑 시공',
  'paint.paint.ppf': 'PPF 시공',
  exterior: '외관 상태',
  'exterior.front': '전방',
  'exterior.front.frontBumper': '앞범퍼',
  'exterior.front.frontBumperMolding': '앞범퍼 몰딩',
  'exterior.front.grille': '그릴',
  'exterior.front.headlamp': '헤드램프',
  'exterior.front.turnSignal': '방향 지시등',
  'exterior.front.frontWindshield': '앞유리',
  'exterior.front.tinting': '썬팅',
  'exterior.front.hood': '후드',
  'exterior.right': '우측',
  'exterior.right.fender': '휀더',
  'exterior.right.frontWheel': '프론트 휠',
  'exterior.right.rearWheel': '리어 휠',
  'exterior.right.sideMirror': '사이드 미러',
  'exterior.right.frontDoor': '앞문',
  'exterior.right.rearDoor': '뒷문',
  'exterior.right.sideSealPanel': '사이드실 패널',
  'exterior.right.quarterPanel': '쿼터 패널',
  'exterior.right.aPillar': 'A 필러',
  'exterior.right.bPillar': 'B 필러',
  'exterior.right.cPillar': 'C 필러',
  'exterior.right.dPillar': 'D 필러',
  'exterior.right.doorGarnish': '가니쉬 (도어)',
  'exterior.right.doorMolding': '몰딩 (도어)',
  'exterior.right.fenderGarnish': '가니쉬 (휀더)',
  'exterior.right.quarterPanelGarnish': '가니쉬 (쿼터패널)',
  'exterior.rear': '후방',
  'exterior.rear.tailLamp': '테일램프',
  'exterior.rear.rearWindowTinting': '뒷유리 썬팅',
  'exterior.rear.rearBumper': '뒷범퍼',
  'exterior.rear.rearBumperMolding': '뒷범퍼 몰딩',
  'exterior.rear.trunkLid': '트렁크 리드',
  'exterior.left': '좌측',
  'exterior.left.fender': '휀더',
  'exterior.left.frontWheel': '프론트 휠',
  'exterior.left.rearWheel': '리어 휠',
  'exterior.left.sideMirror': '사이드 미러',
  'exterior.left.frontDoor': '앞문',
  'exterior.left.rearDoor': '뒷문',
  'exterior.left.sideSealPanel': '사이드실 패널',
  'exterior.left.quarterPanel': '쿼터 패널',
  'exterior.left.aPillar': 'A 필러',
  'exterior.left.bPillar': 'B 필러',
  'exterior.left.cPillar': 'C 필러',
  'exterior.left.dPillar': 'D 필러',
  'exterior.left.doorGarnish': '가니쉬 (도어)',
  'exterior.left.doorMolding': '몰딩 (도어)',
  'exterior.left.fenderGarnish': '가니쉬 (휀더)',
  'exterior.left.quarterPanelGarnish': '가니쉬 (쿼터패널)',
  'exterior.roof': '루프',
  'exterior.roof.roof': '루프',
  'exterior.muffler': '머플러',
  'exterior.muffler.muffler': '머플러',
  options: '옵션 및 기타',
  'options.climateControlSystem': '공조장치',
  'options.climateControlSystem.airConditioner': '에어컨',
  'options.climateControlSystem.heater': '히터',
  'options.climateControlSystem.blowerMotor': '송풍 모터',
  'options.climateControlSystem.blowerDirectionController': '송풍 방향 장치',
  'options.climateControlSystem.autoAirConditioner': '오토 에어컨',
  'options.avSystem': 'AV 시스템',
  'options.avSystem.radio': '라디오',
  'options.avSystem.monitor': '모니터',
  'options.steeringHandle': '전동식 조절 스티어링 휠',
  'options.steeringHandle.electricalSteeringHandle': '전동식 조절 스티어링 휠',
  'options.navigation': '네비게이션',
  'options.navigation.navigation': '네비게이션',
  'options.navigation.touchPanel': '터치 패널',
  'options.powerWindow': '파워 윈도우',
  'options.powerWindow.powerWindow': '파워 윈도우',
  'options.wiper': '와이퍼',
  'options.wiper.wiper': '와이퍼',
  'options.autoWiper': '오토 와이퍼',
  'options.autoWiper.autoWiper': '오토 와이퍼',
  'options.autoFoldSideMirror': '전동접이 사이드 미러',
  'options.autoFoldSideMirror.autoFoldSideMirror': '전동접이 사이드 미러',
  'options.sunroof': '썬루프',
  'options.sunroof.sunroof': '썬루프',
  'options.panoramicSunroof': '파노라마 썬루프',
  'options.panoramicSunroof.panoramicSunroof': '파노라마 썬루프',
  'options.camera': '카메라',
  'options.camera.rear': '후방',
  'options.camera.around': '어라운드 뷰',
  'options.leatherSeat': '가죽 시트',
  'options.leatherSeat.leatherSeat': '가죽 시트',
  'options.powerSeat': '전동 시트',
  'options.powerSeat.powerSeat': '전동 시트',
  'options.ventilatedSeat': '통풍 시트',
  'options.ventilatedSeat.ventilatedSeat': '통풍 시트',
  'options.heatedSeat': '열선 시트',
  'options.heatedSeat.heatedSeat': '열선 시트',
  'options.indoorLight': '실내등 (앰비언트등)',
  'options.indoorLight.indoorLight': '실내등 (앰비언트등)',
  'options.hud': '헤드업 디스플레이 (HUD)',
  'options.hud.hud': '헤드업 디스플레이 (HUD)',
  'options.heatedSteeringWheel': '열선 스티어링 휠',
  'options.heatedSteeringWheel.heatedSteeringWheel': '열선 스티어링 휠',
  'options.headlamp': '헤드램프 (LED)',
  'options.headlamp.headlamp': '헤드램프 (LED)',
  'options.autoTrunk': '전동 트렁크',
  'options.autoTrunk.autoTrunk': '전동 트렁크',
  'options.epb': '전자식 파킹 브레이크 (EPB)',
  'options.epb.epb': '전자식 파킹 브레이크 (EPB)',
  'options.aeb': '자동 긴급 제동장치 (AEB)',
  'options.aeb.aeb': '자동 긴급 제동장치 (AEB)',
  'options.ldws': '차선 이탈 경보 장치 (LDWS)',
  'options.ldws.ldws': '차선 이탈 경보 장치 (LDWS)',
  'options.bsd': '후측방 경보 (BSD)',
  'options.bsd.bsd': '후측방 경보 (BSD)',
  'options.tpms': '타이어 공기압 자동 감지 시스템 (TPMS)',
  'options.tpms.tpms': '타이어 공기압 자동 감지 시스템 (TPMS)',
  'options.bluetooth': '블루투스',
  'options.bluetooth.bluetooth': '블루투스',
  'options.smartKey': '스마트 키',
  'options.smartKey.smartKey': '스마트 키',
  'options.cruiseControl': '크루즈 컨트롤',
  'options.cruiseControl.cruiseControl': '크루즈 컨트롤',
  'options.parkingSensor': '주차 센서',
  'options.parkingSensor.front': '전방',
  'options.parkingSensor.rear': '후방',
  interiorCondition: '실내 상태',
  'interiorCondition.dashboard': '대시보드',
  'interiorCondition.dashboard.dashboard': '대시보드',
  'interiorCondition.centerFascia': '센터페시아',
  'interiorCondition.centerFascia.centerFascia': '센터페시아',
  'interiorCondition.centerConsole': '센터 콘솔',
  'interiorCondition.centerConsole.centerConsole': '센터 콘솔',
  'interiorCondition.seat': '시트',
  'interiorCondition.seat.row1': '1열',
  'interiorCondition.seat.row2': '2열',
  'interiorCondition.seat.row3': '3열',
  'interiorCondition.headliner': '헤드라이너',
  'interiorCondition.headliner.roof': '루프',
  'interiorCondition.headliner.pillarCover': '필러 커버',
  'interiorCondition.handle': '핸들',
  'interiorCondition.handle.handle': '핸들',
  'interiorCondition.doorTrim': '도어 트림',
  'interiorCondition.doorTrim.doorTrim': '도어 트림',
  'interiorCondition.driverSeatMat': '운전석 매트',
  'interiorCondition.driverSeatMat.driverSeatMat': '운전석 매트',
  photos: '사진',
  'photos.exterior': '실외 사진',
  'photos.wheel': '휠 사진',
  'photos.interior': '실내 사진',
  'photos.carRegistration': '자동차 등록증',
  'photos.paperReport': '종이 체크리스트',
  'photos.option': '옵션 사진',
  'photos.underBody': '하체 사진',
  'photos.dashboard': '계기판 사진',
  'photos.maintenanceReceipts': '정비내역 영수증',
  opinion: '기타 의견',
  diagnosis: '자기진단',
  'diagnosis.etc': '기타',
  'diagnosis.engine': '원동기',
  'diagnosis.transmission': '변속기',
  'diagnosis.imgs': '1차 사진',
  'diagnosis.imgsAfterClear': '2차 사진',
  pros: '장점',
  notice: '고지 사항',
};

export type StartsWith<T extends string, S extends string> = T extends `${S}.${string}.${string}`
  ? T
  : never;
export type OptionPaths = StartsWith<MechanicReportPath, 'options'>;

export const optionFullTexts: Record<OptionPaths, string> = {
  'options.climateControlSystem.airConditioner':
    reportTexts['options.climateControlSystem.airConditioner'],
  'options.climateControlSystem.heater': reportTexts['options.climateControlSystem.heater'],
  'options.climateControlSystem.blowerMotor':
    reportTexts['options.climateControlSystem.blowerMotor'],
  'options.climateControlSystem.blowerDirectionController':
    reportTexts['options.climateControlSystem.blowerDirectionController'],
  'options.climateControlSystem.autoAirConditioner':
    reportTexts['options.climateControlSystem.autoAirConditioner'],
  'options.avSystem.radio': reportTexts['options.avSystem.radio'],
  'options.avSystem.monitor': reportTexts['options.avSystem.monitor'],
  'options.steeringHandle.electricalSteeringHandle':
    reportTexts['options.steeringHandle.electricalSteeringHandle'],
  'options.navigation.navigation': reportTexts['options.navigation.navigation'],
  'options.navigation.touchPanel': reportTexts['options.navigation.touchPanel'],
  'options.powerWindow.powerWindow': reportTexts['options.powerWindow.powerWindow'],
  'options.wiper.wiper': reportTexts['options.wiper.wiper'],
  'options.autoWiper.autoWiper': reportTexts['options.autoWiper.autoWiper'],
  'options.autoFoldSideMirror.autoFoldSideMirror':
    reportTexts['options.autoFoldSideMirror.autoFoldSideMirror'],
  'options.sunroof.sunroof': reportTexts['options.sunroof.sunroof'],
  'options.panoramicSunroof.panoramicSunroof':
    reportTexts['options.panoramicSunroof.panoramicSunroof'],
  'options.camera.rear': '후방 카메라',
  'options.camera.around': '360도 어라운드 뷰',
  'options.leatherSeat.leatherSeat': reportTexts['options.leatherSeat.leatherSeat'],
  'options.powerSeat.powerSeat': reportTexts['options.powerSeat.powerSeat'],
  'options.ventilatedSeat.ventilatedSeat': reportTexts['options.ventilatedSeat.ventilatedSeat'],
  'options.heatedSeat.heatedSeat': reportTexts['options.heatedSeat.heatedSeat'],
  'options.indoorLight.indoorLight': reportTexts['options.indoorLight.indoorLight'],
  'options.hud.hud': reportTexts['options.hud.hud'],
  'options.heatedSteeringWheel.heatedSteeringWheel':
    reportTexts['options.heatedSteeringWheel.heatedSteeringWheel'],
  'options.headlamp.headlamp': reportTexts['options.headlamp.headlamp'],
  'options.autoTrunk.autoTrunk': reportTexts['options.autoTrunk.autoTrunk'],
  'options.epb.epb': reportTexts['options.epb.epb'],
  'options.aeb.aeb': reportTexts['options.aeb.aeb'],
  'options.ldws.ldws': reportTexts['options.ldws.ldws'],
  'options.bsd.bsd': reportTexts['options.bsd.bsd'],
  'options.tpms.tpms': reportTexts['options.tpms.tpms'],
  'options.bluetooth.bluetooth': reportTexts['options.bluetooth.bluetooth'],
  'options.smartKey.smartKey': reportTexts['options.smartKey.smartKey'],
  'options.cruiseControl.cruiseControl': reportTexts['options.cruiseControl.cruiseControl'],
  'options.parkingSensor.front': '주차 센서: 전방',
  'options.parkingSensor.rear': '주차 센서: 후방',
};

export const reportPaths = objectKeys(reportTexts);

export const getReportText = (path: MechanicReportPath, includeDirectionInBrackets?: boolean) => {
  const [category, group, part] = path.split('.');
  const categoryText = reportTexts[category as MechanicReportPath];
  const groupText = reportTexts[[category, group].filter(Boolean).join('.') as MechanicReportPath];
  const partText =
    reportTexts[[category, group, part].filter(Boolean).join('.') as MechanicReportPath];
  const directionKeywords = ['front', 'right', 'rear', 'left', 'around'];
  const isGroupDirection = directionKeywords.includes(group);
  const isPartDirection = directionKeywords.includes(part);
  const groupAndPartText =
    groupText === partText
      ? groupText
      : match({ includeDirectionInBrackets, isGroupDirection, isPartDirection })
          .with(
            {
              includeDirectionInBrackets: true,
              isGroupDirection: true,
              isPartDirection: false,
            },
            () => `${partText} (${groupText})`
          )
          .with(
            {
              includeDirectionInBrackets: true,
              isGroupDirection: false,
              isPartDirection: true,
            },
            () => `${groupText} (${partText})`
          )
          .otherwise(() => `${groupText} > ${partText}`);
  const fullHierarchyText = [categoryText, groupAndPartText].filter(Boolean).join(' > ');

  return {
    categoryText,
    groupText,
    partText,
    groupAndPartText,
    fullHierarchyText,
  };
};

export const reportOrderPriorities = objectKeys(reportTexts).reduce(
  (result, key, index) => {
    result[key] = index;
    return result;
  },
  {} as Record<MechanicReportPath, number>
);

export const reportDescriptions: Partial<Record<MechanicReportPath, string>> = {
  'options.wiper.wiper': '후면 와이퍼가 있는 경우 같이 체크해 주세요.',
};
