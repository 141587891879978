import { ValueOf } from '@daangn/car-utils/type';

export const MechanicReviewFeedbackChoice = {
  ACCURATE: 'ACCURATE',
  DETAILED_EXPLANATION: 'DETAILED_EXPLANATION',
  NEED_DETAILED_EXPLANATION: 'NEED_DETAILED_EXPLANATION',
  NEED_KINDNESS: 'NEED_KINDNESS',
  NEED_PUNCTUALITY: 'NEED_PUNCTUALITY',
  NEED_QUICKNESS: 'NEED_QUICKNESS',
  NEED_THOROUGHNESS: 'NEED_THOROUGHNESS',
  PROFESSIONALISM: 'PROFESSIONALISM',
  PUNCTUAL: 'PUNCTUAL',
  QUICK: 'QUICK',
  THOROUGH: 'THOROUGH',
  WRONG_DIAGNOSIS: 'WRONG_DIAGNOSIS',
} as const;

export type MechanicReviewFeedbackChoice = ValueOf<typeof MechanicReviewFeedbackChoice>;

export const MechanicReviewFeedbackChoiceText: Record<MechanicReviewFeedbackChoice, string> = {
  ACCURATE: '진단 결과가 정확해요.',
  DETAILED_EXPLANATION: '자세하게 설명해 주셨어요.',
  NEED_DETAILED_EXPLANATION: '자세하게 설명해 주시면 좋겠어요.',
  NEED_KINDNESS: '친절하게 대해 주시면 좋겠어요.',
  NEED_PUNCTUALITY: '약속 시간을 잘 지켜 주시면 좋겠어요.',
  NEED_QUICKNESS: '진단 시간을 줄이면 좋겠어요.',
  NEED_THOROUGHNESS: '더 꼼꼼히 보셨으면 좋겠어요.',
  PROFESSIONALISM: '전문성이 느껴져요.',
  PUNCTUAL: '약속 시간을 잘 지켜 주셨어요.',
  QUICK: '빠르게 진단해 주셨어요.',
  THOROUGH: '꼼꼼하게 진단해 주셨어요.',
  WRONG_DIAGNOSIS: '잘못 진단한 항목이 있어요.',
};
