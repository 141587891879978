import { isInRange } from './number';

type Entry = [RegExp, string];

const formatByEntries = (val: string, entries: Entry[]) => {
  for (const [regex, replacer] of entries) {
    if (regex.test(val)) {
      return val.replace(regex, replacer);
    }
  }
  return val;
};

export const getDriveDistanceText = (driveDistance: number): string => {
  let text;

  if (driveDistance < 10000) {
    return driveDistance.toString();
  }
  text = driveDistance / 10000;
  text = driveDistance % 10000 === 0 ? text.toString() : text.toFixed(1);
  text += '만';

  return text;
};

export const maskPhoneNumber = (content: string): string => {
  const MASKING_TEXT = ' ***-****-**** ';
  if (!content) {
    return '';
  }

  return content
    .replace(/공일([공|일|육|칠|팔|구])[일이삼사오육칠팔구십둘셋넷공\s\d-]{7,}/, MASKING_TEXT)
    .replace(/01([0|1|6|7|8|9])(-|.)?([0-9]{3,4})(-|.)?([0-9]{4})/, MASKING_TEXT);
};

export const maskName = (text: string): string => {
  if (!text) {
    return '';
  }
  const name = removeBlank(text);

  if (name.length === 1) {
    return '*';
  }
  if (name.length === 2) {
    return name[0] + '*';
  }
  return name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];
};

export const removeBlank = (value = '') => value.trim().split(' ').join('');

export const isValidCarNumber = (carNo: string) => {
  const carNumberRegex = /^\d{2,3}[가-힣]{1}\d{4}$/gm;
  const validRegions = [
    '서울',
    '경기',
    '인천',
    '부산',
    '대구',
    '대전',
    '광주',
    '울산',
    '강원',
    '충북',
    '충남',
    '전북',
    '전남',
    '경북',
    '경남',
    '제주',
  ];
  let trimmed = removeBlank(carNo);

  if (validRegions.some((region) => trimmed.startsWith(region))) {
    const legacyCarNumberRegex = /^\d{1,2}[가-힣]{1}\d{4}$/gm;

    trimmed = trimmed.slice(2);

    if (!isInRange(trimmed.length, 6, 7)) {
      return false;
    }

    return legacyCarNumberRegex.test(trimmed);
  }

  if (!isInRange(trimmed.length, 7, 8)) {
    return false;
  }

  return carNumberRegex.test(trimmed);
};

export const maskCarNumber = (carNo: string) => {
  if (!isValidCarNumber(carNo)) {
    return carNo;
  }
  return carNo.slice(0, -2) + '**';
};

export const toPhoneNumber = (numText: string) => {
  if (numText.startsWith('02')) {
    return formatByEntries(numText, [
      [/(^02)(\d{3})(\d{1,4})$/, '$1-$2-$3'],
      [/(^02)(\d{4})(\d{1,4})$/, '$1-$2-$3'],
      [/(^02)(\d{4})(\d{4})\d+$/, '$1-$2-$3'],
      [/(^02)(\d{1,4})$/, '$1-$2'],
    ]);
  }

  return formatByEntries(numText, [
    [/(^\d{3})(\d{3})(\d{1,4})$/, '$1-$2-$3'],
    [/(^\d{3})(\d{4})(\d{1,4})$/, '$1-$2-$3'],
    [/(^\d{3})(\d{4})(\d{4})\d+$/, '$1-$2-$3'],
    [/(^\d{3})(\d{1,4})$/, '$1-$2'],
  ]);
};

export const fromPhoneNumber = (numText: string) => numText.replaceAll('-', '');

export const fromEmployeeId = (text: string) =>
  text
    .replace(/[^A-Za-z0-9\-]/g, '')
    .toUpperCase()
    .substring(0, 15);

export const toEmployeeId = (numText: string) => {
  return numText;
};

export function getRandomHex() {
  let result = '';
  const characters = '0123456789abcdef';
  const charactersLength = characters.length;
  for (let i = 0; i < 64; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const isValidEmployeeId = (text: string) => {
  return /^[A-Z]{2}-\d{7}$/.test(text);
};

export const toBusinessNumber = (numText: string) => {
  return formatByEntries(numText, [
    [/(^\d{3})(\d{2})(\d{1,5})$/, '$1-$2-$3'],
    [/(^\d{3})(\d{2})(\d{5})\d+$/, '$1-$2-$3'],
    [/(^\d{3})(\d{1,2})$/, '$1-$2'],
  ]);
};

export const fromBusinessNumber = (numText: string) =>
  numText.replace(/[- ]/g, '').substring(0, 10);

export const isValidBusinessNumber = (text: string) => {
  return /^\d{10}$/.test(text);
};

export const formatCorporationNumber = (text: string) => {
  return text.replace(/(\d{6})(\d{7})/, '$1-$2');
};

export const extractCarNo = (text: string) => {
  const carNoRegexes = [
    /(서울|경기|인천|부산|대구|대전|광주|울산|강원|충북|충남|전북|전남|경북|경남|제주)[0-9]{1,2}[가-힣]{1}[0-9]{4}/,
    /[0-9]{2,3}[가-힣]{1}[0-9]{4}/,
  ];
  return carNoRegexes.find((regex) => regex.test(text))?.exec(text)?.[0];
};
