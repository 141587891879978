import { nonNullish } from '@daangn/car-utils/typeGuard';
import { objectValues } from '@toss/utils';
import { z } from 'zod';

import { noneChecklist } from './defaultMechanicReportFormValue';
import { ReportItemState, ReportItemStateSchema } from './ReportItemState';

export type EpbOptionPart = z.input<typeof EpbOptionPartSchema>;
const EpbOptionPartSchema = z.object({
  epb: ReportItemStateSchema,
});
export type AutoTrunkOptionPart = z.input<typeof AutoTrunkOptionPartSchema>;
const AutoTrunkOptionPartSchema = z.object({
  autoTrunk: ReportItemStateSchema,
});
export type AutoWiperOptionPart = z.input<typeof AutoWiperOptionPartSchema>;
const AutoWiperOptionPartSchema = z.object({
  autoWiper: ReportItemStateSchema,
});
export type HeadlampOptionPart = z.input<typeof HeadlampOptionPartSchema>;
const HeadlampOptionPartSchema = z.object({
  headlamp: ReportItemStateSchema,
});
export type HeatedSteeringWheelOptionPart = z.input<typeof HeatedSteeringWheelOptionPartSchema>;
const HeatedSteeringWheelOptionPartSchema = z.object({
  heatedSteeringWheel: ReportItemStateSchema,
});
export type HudOptionPart = z.input<typeof HudOptionPartSchema>;
const HudOptionPartSchema = z.object({
  hud: ReportItemStateSchema,
});
export type IndoorLightOptionPart = z.input<typeof IndoorLightOptionPartSchema>;
const IndoorLightOptionPartSchema = z.object({
  indoorLight: ReportItemStateSchema,
});
export type HeatedSeatOptionPart = z.input<typeof HeatedSeatOptionPartSchema>;
const HeatedSeatOptionPartSchema = z.object({
  heatedSeat: ReportItemStateSchema,
});
export type VentilatedSeatOptionPart = z.input<typeof VentilatedSeatOptionPartSchema>;
const VentilatedSeatOptionPartSchema = z.object({
  ventilatedSeat: ReportItemStateSchema,
});
export type LeatherSeatOptionPart = z.input<typeof LeatherSeatOptionPartSchema>;
const LeatherSeatOptionPartSchema = z.object({
  leatherSeat: ReportItemStateSchema,
});
export type PowerSeatOptionPart = z.input<typeof PowerSeatOptionPartSchema>;
const PowerSeatOptionPartSchema = z.object({
  powerSeat: ReportItemStateSchema,
});
export type CameraOptionPart = z.input<typeof CameraOptionPartSchema>;
const CameraOptionPartSchema = z.object({
  rear: ReportItemStateSchema,
  around: ReportItemStateSchema,
});
export type SunroofOptionPart = z.input<typeof SunroofOptionPartSchema>;
const SunroofOptionPartSchema = z.object({
  sunroof: ReportItemStateSchema,
});
export type PanoramicSunroofOptionPart = z.input<typeof PanoramicSunroofOptionPartSchema>;
const PanoramicSunroofOptionPartSchema = z.object({
  panoramicSunroof: ReportItemStateSchema,
});
export type WiperMotorOptionPart = z.input<typeof WiperOptionPartSchema>;
const WiperOptionPartSchema = z.object({
  wiper: ReportItemStateSchema,
});

export type ClimateControlSystemOptionPart = z.input<typeof ClimateControlSystemOptionPartSchema>;
const ClimateControlSystemOptionPartSchema = z.object({
  blowerMotor: ReportItemStateSchema,
  blowerDirectionController: ReportItemStateSchema,
  airConditioner: ReportItemStateSchema,
  heater: ReportItemStateSchema,
  autoAirConditioner: ReportItemStateSchema.optional().default(noneChecklist),
});
export type AvSystemOptionPart = z.input<typeof AvSystemOptionPartSchema>;
const AvSystemOptionPartSchema = z.object({
  radio: ReportItemStateSchema,
  monitor: ReportItemStateSchema,
});
export type SteeringHandleOptionPart = z.input<typeof SteeringHandleOptionPartSchema>;
const SteeringHandleOptionPartSchema = z.object({
  electricalSteeringHandle: ReportItemStateSchema.optional().default(noneChecklist),
});

export type NavigationOptionPart = z.input<typeof NavigationOptionPartSchema>;
const NavigationOptionPartSchema = z.object({
  navigation: ReportItemStateSchema,
  touchPanel: ReportItemStateSchema,
});
export type PowerWindowOptionPart = z.input<typeof PowerWindowOptionPartSchema>;
const PowerWindowOptionPartSchema = z.object({
  powerWindow: ReportItemStateSchema,
});

export type AutoFoldSideMirrorOptionPart = z.input<typeof AutoFoldSideMirrorOptionPartSchema>;
const AutoFoldSideMirrorOptionPartSchema = z.object({
  autoFoldSideMirror: ReportItemStateSchema,
});

export type ParkingSensorOptionPart = z.input<typeof ParkingSensorOptionPartSchema>;
const ParkingSensorOptionPartSchema = z.object({
  front: ReportItemStateSchema,
  rear: ReportItemStateSchema,
});

export type LDWSOptionPart = z.input<typeof LDWSOptionPartSchema>;
const LDWSOptionPartSchema = z.object({
  ldws: ReportItemStateSchema,
});

export type BSDOptionPart = z.input<typeof BSDOptionPartSchema>;
const BSDOptionPartSchema = z.object({
  bsd: ReportItemStateSchema,
});

export type AEBOptionPart = z.input<typeof AEBOptionPartSchema>;
const AEBOptionPartSchema = z.object({
  aeb: ReportItemStateSchema,
});

export type TPMSOptionPart = z.input<typeof TPMSOptionPartSchema>;
const TPMSOptionPartSchema = z.object({
  tpms: ReportItemStateSchema,
});

export type BluetoothOptionPart = z.input<typeof BluetoothOptionPartSchema>;
const BluetoothOptionPartSchema = z.object({
  bluetooth: ReportItemStateSchema,
});

export type SmartKeyOptionPart = z.input<typeof SmartKeyOptionPartSchema>;
const SmartKeyOptionPartSchema = z.object({
  smartKey: ReportItemStateSchema,
});

export type CruiseControlOptionPart = z.input<typeof CruiseControlOptionPartSchema>;
const CruiseControlOptionPartSchema = z.object({
  cruiseControl: ReportItemStateSchema,
});

export type OptionsChecklist = z.input<typeof OptionsChecklistSchema>;
export const OptionsChecklistSchema = z
  .object({
    climateControlSystem: ClimateControlSystemOptionPartSchema,
    avSystem: AvSystemOptionPartSchema,
    steeringHandle: SteeringHandleOptionPartSchema,
    navigation: NavigationOptionPartSchema,
    powerWindow: PowerWindowOptionPartSchema,
    wiper: WiperOptionPartSchema,
    autoFoldSideMirror: AutoFoldSideMirrorOptionPartSchema,
    sunroof: SunroofOptionPartSchema,
    panoramicSunroof: PanoramicSunroofOptionPartSchema,
    camera: CameraOptionPartSchema,
    powerSeat: PowerSeatOptionPartSchema,
    ventilatedSeat: VentilatedSeatOptionPartSchema,
    heatedSeat: HeatedSeatOptionPartSchema,
    indoorLight: IndoorLightOptionPartSchema,
    hud: HudOptionPartSchema,
    heatedSteeringWheel: HeatedSteeringWheelOptionPartSchema,
    headlamp: HeadlampOptionPartSchema,
    autoTrunk: AutoTrunkOptionPartSchema,
    autoWiper: AutoWiperOptionPartSchema.optional().default({
      autoWiper: noneChecklist,
    }),
    epb: EpbOptionPartSchema,
    leatherSeat: LeatherSeatOptionPartSchema.optional().default({
      leatherSeat: noneChecklist,
    }),
    parkingSensor: ParkingSensorOptionPartSchema.optional().default({
      front: noneChecklist,
      rear: noneChecklist,
    }),
    // 차선 이탈 경보 장치  (Lane Departure Warning System)
    ldws: LDWSOptionPartSchema.optional().default({
      ldws: noneChecklist,
    }),
    // 후측방 경보 (Blind Spot Detection)
    bsd: BSDOptionPartSchema.optional().default({
      bsd: noneChecklist,
    }),
    // 자동 긴급 제동장치 (Autonomous Emergency Braking)
    aeb: AEBOptionPartSchema.optional().default({
      aeb: noneChecklist,
    }),
    // 타이어 공기압 자동감지 시스템 (Tire Pressure Monitoring System)
    tpms: TPMSOptionPartSchema.optional().default({
      tpms: noneChecklist,
    }),
    bluetooth: BluetoothOptionPartSchema.optional().default({
      bluetooth: noneChecklist,
    }),
    smartKey: SmartKeyOptionPartSchema.optional().default({
      smartKey: noneChecklist,
    }),
    cruiseControl: CruiseControlOptionPartSchema.optional().default({
      cruiseControl: noneChecklist,
    }),
  })
  .refine((val) => {
    const isEmptyAll = objectValues(val)
      .filter(nonNullish)
      .flatMap((group) => objectValues(group))
      .every((v: ReportItemState) => v.selections.includes('None'));
    return !isEmptyAll;
  }, '옵션 유무와 상태를 확인해 주세요.');
