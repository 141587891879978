import '@daangn/error-component/index.css';
import '@daangn/karrot-clothes/lib/index.css';
import '@daangn/sprout-components-avatar/index.css';
import '@daangn/sprout-components-button/index.css';
import '@daangn/sprout-components-callout/index.css';
import '@daangn/sprout-components-checkbox/index.css';
import '@daangn/sprout-components-chips/index.css';
import '@daangn/sprout-components-dialog/index.css';
import '@daangn/sprout-components-divider/index.css';
import '@daangn/sprout-components-radio-group/index.css';
import '@daangn/sprout-components-select-box/index.css';
import '@daangn/sprout-components-slider/index.css';
import '@daangn/sprout-components-snackbar/index.css';
import '@daangn/sprout-components-spinner/index.css';
import '@daangn/sprout-components-tabs/index.css';
import '@daangn/sprout-components-text-field/index.css';
import '@daangn/transfer-identification-sdk/index.css';
import '@seed-design/stylesheet/global.css';
import '@seed-design/stylesheet/token.css';
import '@daangn/car-react/components.css';
import '@stackflow/plugin-basic-ui/index.css';
import { makeTemplate } from '@stackflow/plugin-history-sync';

import { IS_ALPHA, IS_LOCAL } from '@/constants/config';
import { stackflowConfig } from '@/stackflow/stackflow.config';
import '@/utils/datadog';
import '@/utils/sentry';

import './bootstrap/setup';
import './bootstrap/shim';
import './index.css';
import './utils/zodI18n';

if (IS_ALPHA || IS_LOCAL) {
  import('./bootstrap/v-console').then((vConsole) => vConsole.default());
}

async function main() {
  let initialLoaderData: any | null = null;

  for (const activity of stackflowConfig.activities) {
    const t = makeTemplate({ path: activity.path });
    const match = t.parse(location.pathname + location.search);

    if (!match) {
      continue;
    }
    if ((activity as any).loader as any) {
      initialLoaderData = (activity as any).loader({ params: match as any });
    }
    break;
  }

  // 2. 동시에 리액트 앱을 다운로드 받습니다.
  const { renderApp } = await import('./bootstrap/renderApp');

  // 3. 둘을 결합합니다.
  renderApp({ initialLoaderData });
}

main();
