import { ValueOf } from '@daangn/car-utils/type';

// INFO: graphql의 CarDataColor와 일치하는 항목
export const CarColorType = {
  WHITE: 'WHITE',
  IVORY: 'IVORY',
  YELLOW: 'YELLOW',
  RED: 'RED',
  GRAY: 'GRAY',
  GREEN: 'GREEN',
  BLUE: 'BLUE',
  PURPLE: 'PURPLE',
  BLACK: 'BLACK',
  BROWN: 'BROWN',
  ETC: 'ETC',
} as const;

export type CarColorType = ValueOf<typeof CarColorType>;

export type ColorInfo = {
  background: string;
  label: string;
};

export const CAR_COLORS: { [key in CarColorType]: ColorInfo } = {
  WHITE: {
    label: '흰색',
    background: '#FFFFFF',
  },
  IVORY: {
    label: '베이지',
    background: '#F2EFD6',
  },
  YELLOW: {
    label: '노랑',
    background: '#FFE733',
  },
  RED: {
    label: '빨강(주황)',
    background: '#FF4133',
  },
  GRAY: {
    label: '회색',
    background: '#ADB1BA',
  },
  GREEN: {
    label: '초록(연두)',
    background: '#1AA174',
  },
  BLUE: {
    label: '파랑(남색)',
    background: '#4A81F0',
  },
  PURPLE: {
    label: '보라',
    background: '#9675FA',
  },
  BLACK: {
    label: '검정',
    background: '#393A40',
  },
  BROWN: {
    label: '갈색',
    background: '#BA5E02',
  },
  ETC: {
    label: '기타',
    background:
      'conic-gradient(from 0deg at 50% 50%, #FF3838 0deg, #FFF04C 77.40000128746033deg, #52E429 180deg, #3B77D8 277.1999931335449deg, #FF3838 360deg), #4B84FF',
  },
} as const;

function isCarColorType(colorType: string): colorType is CarColorType {
  return Object.hasOwn(CAR_COLORS, colorType);
}

export function getColorInfo(colorType: null | string | undefined) {
  if (!colorType) {
    return CAR_COLORS.ETC;
  }

  if (isCarColorType(colorType)) {
    return CAR_COLORS[colorType];
  }

  return CAR_COLORS.ETC;
}

export type CarColorInformation = (typeof CAR_COLORS)[CarColorType];
