import { getActiveTestUser } from '@/utils/dev';

const common = {
  SERVICE_NAME: '당근 중고차',
  KAKAO_API_HOST: 'https://dapi.kakao.com',
  SENTRY_DSN: 'https://cee0e59cc69a4d598066a5a5850b8111@o24217.ingest.sentry.io/5962465',
  OBD_CODE_HOST: 'https://car-obd-codes.karrot.workers.dev',
} as const;

const alpha = {
  ...common,
  SERVICE_KEY: 'CAR_ALPHA',
  WEBVIEW_HOST: 'https://car.alpha.karrotwebview.com',
  API_HOST: 'https://car.alpha.kr.karrotmarket.com',
  BIZ_ACCOUNT_HOST: 'https://ba.alpha.kr.karrotmarket.com',
  BIZ_PROFILE_HOST: 'https://bizprofile.alpha.karrotwebview.com',
  BIGPICTURE_HOST: 'https://image.alpha.kr.karrotmarket.com',
  EXPERIMENTATION_HOST: 'https://experimentation-platform.alpha.kr.karrotmarket.com',
  REPORT_HOST: 'https://report.alpha.karrotwebview.com',
  SHORTCUT_HOST: 'https://shortcut.alpha.kr.karrotmarket.com',
  IDENTIFICATION_HOST: 'https://identification.alpha.kr.karrotmarket.com',
  APP_SCHEME: 'karrot.alpha',
  GA_MEASUREMENT_ID: 'G-08XT0MF8KT',
  AMPLITUDE_API_KEY: 'a58af60d10630e082ab6ec3f69a5c6f0',
  MIXPANEL_TOKEN: '8afe5da0a1ac216338e212270f2cf276',
  NEARBY_CAR_SERVICE_ID: '48',
  PAYMENT_CLIENT_SECRET: 'UnB6HjY7MZ2Co1f',
  PAYMENT_CLIENT_SECRET_SAFE_TRADE: 'F6LwYHAYHiYV6OA',
} as const;

const production = {
  ...common,
  SERVICE_KEY: 'CAR',
  WEBVIEW_HOST: 'https://car.karrotwebview.com',
  API_HOST: 'https://car.kr.karrotmarket.com',
  BIZ_ACCOUNT_HOST: 'https://ba.kr.karrotmarket.com',
  BIZ_PROFILE_HOST: 'https://bizprofile.karrotwebview.com',
  BIGPICTURE_HOST: 'https://image.kr.karrotmarket.com',
  EXPERIMENTATION_HOST: 'https://experimentation-platform.kr.karrotmarket.com',
  REPORT_HOST: 'https://report.karrotwebview.com',
  SHORTCUT_HOST: 'https://shortcut.kr.karrotmarket.com',
  IDENTIFICATION_HOST: 'https://identification.kr.karrotmarket.com',
  APP_SCHEME: 'karrot',
  GA_MEASUREMENT_ID: 'G-VQL8GV0KN4',
  AMPLITUDE_API_KEY: '34a724e55245b42ca6819a52123196ab',
  MIXPANEL_TOKEN: '25b0cac2052ef57c9e6205fa6cc0dc89',
  NEARBY_CAR_SERVICE_ID: '45',
  PAYMENT_CLIENT_SECRET: 'UKnoq5vTZ8Bhn20',
  PAYMENT_CLIENT_SECRET_SAFE_TRADE: 'lOzQgmzUEoaOavR',
} as const;

const activeTestUser = getActiveTestUser();

export const ALL_BIG_PICTURE_CDN_IMAGE_URLS = [
  // production s3 빅피쳐 cdn url
  'https://dnvefa72aowie.cloudfront.net',
  // production gcp 빅피쳐 cdn url
  'https://img.kr.gcp-karroter.net',
  // alpha s3 빅피쳐 cdn url
  'https://d4zbl7j2h4ct2.cloudfront.net',
  // alpha gcp 빅피쳐 cdn url
  'https://img.alpha.kr.gcp-karroter.net',
];

export const IS_LOCAL = process.env.NODE_ENV === 'development';
export const IS_ALPHA =
  process.env.NODE_ENV === 'production' && process.env.WEBVIEW_STAGE === 'alpha';
export const IS_PROD = process.env.WEBVIEW_STAGE === 'production' || !!activeTestUser?.production;

export const IS_ANDROID = /Android/i.test(navigator.userAgent);

const CONFIG = IS_PROD ? production : alpha;

export default CONFIG;
