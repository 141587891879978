import { graphql, loadQuery } from 'react-relay';

import type { loader_MainQuery } from '@/__generated__/loader_MainQuery.graphql';

import {
  convertToFeedV2Input,
  defaultFilterState,
} from '@/components/Filter/FilterContextProvider';
import environment from '@/relay/environment';
import { jotaiStore, regionState } from '@/store/user';
import { carStorage } from '@/utils/storage';

export const MainQuery = graphql`
  query loader_MainQuery($filter: ArticleFeedV2Input!, $count: Int!, $regionId: ID!) {
    ...MainBuyerQueryContainer_query @arguments(filter: $filter, count: $count)
    ...MainSellerQueryContainer_query @arguments(regionId: $regionId)
  }
`;

export const mainLoader = async () => {
  const { centerCoordinates, id } = await jotaiStore.get(regionState);

  return {
    preloadedQuery: loadQuery<loader_MainQuery>(environment, MainQuery, {
      count: 20,
      filter: convertToFeedV2Input(
        carStorage.getFilter() ?? defaultFilterState,
        'LATEST',
        centerCoordinates
      ),
      regionId: id.toString(),
    }),
  };
};
