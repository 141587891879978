import type { ActivityLoaderArgs } from '@stackflow/config';

import { graphql, loadQuery } from 'react-relay';

import type { loader_ArticleQuery } from '@/__generated__/loader_ArticleQuery.graphql';

import environment from '@/relay/environment';
import { jotaiStore, userState } from '@/store/user';

export const ArticleQuery = graphql`
  query loader_ArticleQuery($id: ID!, $userId: ID!) {
    me {
      id
      ...ArticleExpenseSection_user
    }
    article(id: $id) {
      article {
        ...Article_article
      }
      ...ArticleError_articleResponse
      errors {
        type
      }
    }
    recommendedArticles(maxCount: 6, articleId: $id) {
      id
      deletedAt
      ...ArticleCard_article
    }
    ...ArticleNoticeSection_query @arguments(userId: $userId)
    ...useNotificationCreatable_query
  }
`;

export const articleLoader = async ({ params }: ActivityLoaderArgs<'article'>) => {
  const { articleId } = params;
  const { id: userId } = await jotaiStore.get(userState);
  return {
    preloadedQuery: loadQuery<loader_ArticleQuery>(environment, ArticleQuery, {
      id: articleId,
      userId: String(userId),
    }),
  };
};
