import type { ReportItemStatePath, ReportItemStateSelection } from '@daangn/car-types';

// [전체 선택 가능한 목록, 사용자에게 정상으로 노출할 목록]
export const selectionListTable: Record<ReportItemStatePath, ReportItemStateSelection[][]> = {
  'baseInfo.tuningHistory': [
    ['TuningEquipment', 'TuningStructure', 'TuningIllegalEquipment', 'TuningIllegalStructure'],
    ['None', 'NoIssues'],
  ],
  'tire.tireCondition.front': [
    ['TireOneSidedWear', 'Damaged', 'Hardened'],
    ['None', 'NoIssues'],
  ],
  'tire.tireCondition.rear': [
    ['TireOneSidedWear', 'Damaged', 'Hardened'],
    ['None', 'NoIssues'],
  ],
  'engine.operation.ignition': [
    ['Noise', 'MaintenanceRequired'],
    ['None', 'NoIssues'],
  ],
  'engine.operation.idle': [
    ['Noise', 'MaintenanceRequired'],
    ['None', 'NoIssues'],
  ],
  'engine.operation.acceleration': [
    ['Noise', 'MaintenanceRequired'],
    ['None', 'NoIssues'],
  ],
  'engine.engineOil.flow': [
    ['ReplacementRecommended', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'engine.engineOil.condition': [['ReplacementRequired'], ['None', 'NoIssues']],
  'engine.oilLeak.rockerArmCover': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'engine.oilLeak.cylinderHead': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'engine.oilLeak.engineBlock': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'engine.oilLeak.oilPan': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'engine.oilLeak.frontCover': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'engine.coolant.level': [
    ['ReplacementRecommended', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'engine.coolant.condition': [['ReplacementRequired'], ['None', 'NoIssues']],
  'engine.coolantLeak.cylinderHead': [
    ['MinorWaterLeak', 'WaterLeak'],
    ['None', 'NoIssues'],
  ],
  'engine.coolantLeak.radiator': [
    ['MinorWaterLeak', 'WaterLeak'],
    ['None', 'NoIssues'],
  ],
  'engine.coolantLeak.waterPump': [
    ['MinorWaterLeak', 'WaterLeak'],
    ['None', 'NoIssues'],
  ],
  'engine.highPressurePump.highPressurePump': [['MaintenanceRequired'], ['None', 'NoIssues']],
  'autoTransmission.operation.PRND': [
    ['MinorImpact', 'Impact'],
    ['None', 'NoIssues'],
  ],
  'autoTransmission.operation.DNRP': [
    ['MinorImpact', 'Impact'],
    ['None', 'NoIssues'],
  ],
  'autoTransmission.missionOil.level': [
    ['ReplacementRecommended', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'autoTransmission.missionOil.condition': [['ReplacementRequired'], ['None', 'NoIssues']],
  'autoTransmission.oilLeak.oilPan': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'autoTransmission.oilLeak.missionCase': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'autoTransmission.oilLeak.pump': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'manualTransmission.gearShift.gearShift': [['MaintenanceRequired'], ['None', 'NoIssues']],
  'manualTransmission.oilLeak.oilLeak': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'manualTransmission.operation.operation': [['Noise', 'MaintenanceRequired'], ['Noise']],
  'powertrain.clutchAssembly.clutchAssembly': [['Defect'], ['None', 'NoIssues']],
  'powertrain.driveShaft.rubberBootDamage': [['Defect'], ['None', 'NoIssues']],
  'powertrain.driveShaft.greaseLeak': [['Defect'], ['None', 'NoIssues']],
  'powertrain.differential.caseLeak': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'powertrain.differential.oilSealLeak': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'powertrain.transferCase.caseLeak': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'powertrain.transferCase.oilSealLeak': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'powertrain.propellerShaft.shaftLooseness': [['Defect'], ['None', 'NoIssues']],
  'powertrain.propellerShaft.discDamage': [['Defect'], ['None', 'NoIssues']],
  'powertrain.propellerShaft.bearingDamage': [['Defect'], ['None', 'NoIssues']],
  'steering.operation.steeringLooseness': [['Defect'], ['None', 'NoIssues']],
  'steering.powerOil.level': [
    ['ReplacementRecommended', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'steering.oilLeak.pump': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'steering.oilLeak.gearBox': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'steering.oilLeak.highPressureHose': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues', 'MinorOilLeak'],
  ],
  'steering.tieRodAndBallJoint.tieRodAndBallJoint': [['Defect'], ['None', 'NoIssues']],
  'brakingSystem.operation.vacuumBrakeBooster': [['Defect'], ['None', 'NoIssues']],
  'brakingSystem.brakeFluid.brakeFluid': [['ReplacementRequired'], ['None', 'NoIssues']],
  'brakingSystem.oilLeak.masterCylinder': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues'],
  ],
  'brakingSystem.oilLeak.caliper': [
    ['MinorOilLeak', 'OilLeak'],
    ['None', 'NoIssues'],
  ],
  'brakingSystem.suspension.suspension': [
    ['Defect', 'OilLeak'],
    ['None', 'NoIssues'],
  ],
  'electricalSystem.generator.outputVoltage': [['Defect'], ['None', 'NoIssues']],
  'electricalSystem.generator.noise': [['Defect'], ['None', 'NoIssues']],
  'electricalSystem.starterMotor.operation': [['Defect'], ['None', 'NoIssues']],
  'electricalSystem.radiatorFanMotor.radiatorFanMotor': [['Defect'], ['None', 'NoIssues']],
  'highVoltageElectricalSystem.batteryIsolation.isolation': [['Defect'], ['None', 'NoIssues']],
  'highVoltageElectricalSystem.chargingPortIsolation.isolation': [['Defect'], ['None', 'NoIssues']],
  'highVoltageElectricalSystem.wire.wire': [['Defect'], ['None', 'NoIssues']],
  'fuelSystem.fuelLeak.gasolineLeak': [['MaintenanceRequired'], ['None', 'NoIssues']],
  'fuelSystem.fuelLeak.dieselLeak': [['MaintenanceRequired'], ['None', 'NoIssues']],
  'fuelSystem.fuelLeak.lpgLeak': [['MaintenanceRequired'], ['None', 'NoIssues']],
  'paint.paint.sameColor': [['Issues'], ['None', 'NoIssues']],
  'paint.paint.differentColor': [['Issues'], ['None', 'NoIssues']],
  'paint.paint.wrapping': [['Issues'], ['None', 'NoIssues']],
  'paint.paint.ppf': [['Issues'], ['None', 'NoIssues']],
  'exterior.front.frontBumper': [
    ['Scratch', 'PaintRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.front.grille': [
    ['Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.front.headlamp': [
    ['Scratch', 'Moisture', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.front.turnSignal': [
    ['Scratch', 'Moisture', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.front.frontWindshield': [
    ['Scratch', 'StoneChip', 'RepairRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.front.tinting': [
    ['Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.front.hood': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.front.frontBumperMolding': [
    ['None', 'NoIssues', 'Scratch', 'PaintRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.fender': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.frontWheel': [
    ['Scratch', 'RepairRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.rearWheel': [
    ['Scratch', 'RepairRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.sideMirror': [
    ['Scratch', 'RepairRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.frontDoor': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.rearDoor': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.sideSealPanel': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.quarterPanel': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.aPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.bPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.cPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.dPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.fenderGarnish': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.doorGarnish': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.doorMolding': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.right.quarterPanelGarnish': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.rear.rearBumper': [
    ['Scratch', 'PaintRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.rear.tailLamp': [
    ['Scratch', 'Moisture', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.rear.rearWindowTinting': [
    ['Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.rear.trunkLid': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.rear.rearBumperMolding': [
    ['None', 'NoIssues', 'Scratch', 'PaintRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.fender': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.frontWheel': [
    ['Scratch', 'RepairRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.rearWheel': [
    ['Scratch', 'RepairRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.sideMirror': [
    ['Scratch', 'RepairRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.frontDoor': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.rearDoor': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.sideSealPanel': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.quarterPanel': [
    ['Scratch', 'DoorDing', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.aPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.bPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.cPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.dPillar': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.fenderGarnish': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.doorGarnish': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.doorMolding': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.left.quarterPanelGarnish': [
    ['None', 'NoIssues', 'Scratch', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.muffler.muffler': [
    ['Corrosion', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'exterior.roof.roof': [
    ['Scratch', 'PaintRequired', 'BendingRequired', 'ReplacementRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.dashboard.dashboard': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.centerFascia.centerFascia': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.centerConsole.centerConsole': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.seat.row1': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.seat.row2': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.seat.row3': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.headliner.roof': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.headliner.pillarCover': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.handle.handle': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.doorTrim.doorTrim': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'interiorCondition.driverSeatMat.driverSeatMat': [
    ['Used', 'RepairRequired'],
    ['None', 'NoIssues'],
  ],
  'options.climateControlSystem.airConditioner': [
    ['None', 'NoIssues', 'InspectionRequired', 'Defect'],
    ['None', 'NoIssues', 'InspectionRequired'],
  ],
  'options.climateControlSystem.autoAirConditioner': [
    ['None', 'NoIssues', 'InspectionRequired', 'Defect'],
    ['None', 'NoIssues', 'InspectionRequired'],
  ],
  'options.climateControlSystem.heater': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.climateControlSystem.blowerMotor': [
    ['None', 'NoIssues', 'Noise', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.climateControlSystem.blowerDirectionController': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.avSystem.radio': [
    ['None', 'NoIssues', 'Noise', 'Defect'],
    ['None', 'NoIssues', 'Noise'],
  ],
  'options.avSystem.monitor': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.steeringHandle.electricalSteeringHandle': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.navigation.navigation': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.navigation.touchPanel': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.powerWindow.powerWindow': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.wiper.wiper': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.autoWiper.autoWiper': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.autoFoldSideMirror.autoFoldSideMirror': [
    ['None', 'NoIssues', 'Noise', 'Defect'],
    ['None', 'NoIssues', 'Noise'],
  ],
  'options.sunroof.sunroof': [
    ['None', 'NoIssues', 'Noise', 'Defect'],
    ['None', 'NoIssues', 'Noise'],
  ],
  'options.panoramicSunroof.panoramicSunroof': [
    ['None', 'NoIssues', 'Noise', 'Defect'],
    ['None', 'NoIssues', 'Noise'],
  ],
  'options.camera.rear': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.camera.around': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.leatherSeat.leatherSeat': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.powerSeat.powerSeat': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.ventilatedSeat.ventilatedSeat': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.heatedSeat.heatedSeat': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.indoorLight.indoorLight': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.hud.hud': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.heatedSteeringWheel.heatedSteeringWheel': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.headlamp.headlamp': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.autoTrunk.autoTrunk': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.epb.epb': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.parkingSensor.front': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.parkingSensor.rear': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.tpms.tpms': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.bluetooth.bluetooth': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.smartKey.smartKey': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.cruiseControl.cruiseControl': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.ldws.ldws': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.bsd.bsd': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
  'options.aeb.aeb': [
    ['None', 'NoIssues', 'Defect'],
    ['None', 'NoIssues'],
  ],
};
