import { AccidentType, AuctionSellerDocumentType, CompanyOrigin } from '@daangn/car-types';
import { Prettify } from '@daangn/car-utils/type';
import { z } from 'zod';

import {
  SellingDocumentQuestion,
  SellingDocumentQuestionAnswer,
} from '@/pages/AuctionTradeSellingDocuments/constants';
import { mechanicReviewRatingTypes } from '@/pages/WriteMechanicReview/schema';
import { zDecoder } from '@/stackflow/zDecoder';
import { WriteStep } from '@/store/write';
import { AgreementId } from '@/types/Agreement';
import { DeliveryStep } from '@/types/AuctionTrade/Delivery/DeliveryStep';
import { ReservationStep } from '@/types/ReserveInspection/ReservationStep';

export const activityDescription = {
  article: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        action: zDecoder.str().optional(),
        afterWriteArticle: zDecoder.bool().optional(),
      })
      .passthrough(),
    url: '/articles/:articleId', // '매물 상세'
  },
  article_car_history: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        to: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/car_history', // '게시글 -> 카 히스토리 상세'
  },
  inspection_car_history: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/car_history', // '진단 -> 카 히스토리 상세'
  },
  article_change_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/change_price', // '가격 변경'
  },
  article_channel_list: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/channel_list', // '매물 채팅 목록'
  },
  article_checklist: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/checklist', // '매물 체크리스트'
  },
  article_images: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/images', // '매물 이미지 목록'
  },
  article_lease_rental: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/lease_rental', // '렌트/리스 상세'
  },
  article_options_list: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/options', // '매물 옵션 상세'
  },
  article_select_buyer: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/select_buyer', // '구매자 지정'
  },
  article_select_reserver: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/select_reserver', // '예약자 지정'
  },
  article_summary: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/summary', // '거래 내역 상세'
  },
  article_proxy_inspection_guide: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/proxy_inspection_guide', // '출장진단 가이드'
  },
  article_proxy_inspection_payment: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/proxy_inspection_payment', // '출장진단 결제'
  },
  curation: {
    schema: z
      .object({
        curationId: zDecoder.str(),
      })
      .passthrough(),
    url: '/curation/:curationId', // '큐레이션'
  },
  dev_laboratory: {
    schema: z.object({}).passthrough(),
    url: '/dev_laboratory', // '개발 실험실'
  },
  edit_article: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        step: zDecoder.nativeEnum(WriteStep).optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/edit', // '글 수정'
  },
  edit_article_images: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/edit_images', // '글 수정'
  },
  fetch_car_info: {
    schema: z
      .object({
        articleId: zDecoder.str().optional(),
        carNo: zDecoder.str().optional(),
        ownerName: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/fetch_car_info', // '차량 조회'
  },
  fetch_auction_car_info: {
    schema: z.object({}).passthrough(),
    url: '/fetch_auction_car_info', // '경매 차량 조회'
  },
  fetch_auction_car_info_restricted: {
    schema: z
      .object({
        ownerName: zDecoder.str(),
        carNo: zDecoder.str(),
      })
      .passthrough(),
    url: '/fetch_auction_car_info/restricted', // '경매 차량 조회 - 제한 차종'
  },
  fetch_auction_car_info_some_time_later: {
    schema: z
      .object({
        ownerName: zDecoder.str(),
        carNo: zDecoder.str(),
      })
      .passthrough(),
    url: '/fetch_auction_car_info/some_time_later', // '경매 차량 조회 - 일정 시간 후 팔기'
  },
  fetch_auction_car_info_seizure_or_mortgage: {
    schema: z
      .object({
        ownerName: zDecoder.str(),
        carNo: zDecoder.str(),
      })
      .passthrough(),
    url: '/fetch_auction_car_info/seizure_or_mortgage', // '경매 차량 조회 - 압류 저당'
  },
  fetch_auction_car_info_replace_article: {
    schema: z
      .object({
        ownerName: zDecoder.str(),
        carNo: zDecoder.str(),
      })
      .passthrough(),
    url: '/fetch_auction_car_info/replace_article', // '경매 차량 조회 - 직접 팔기 게시글 삭제'
  },
  hot_articles: {
    schema: z.object({}).passthrough(),
    url: '/hot_articles', // '지금 인기있는 매물'
  },
  inspected_articles: {
    schema: z.object({}).passthrough(),
    url: '/inspected_articles', // '진단 매물 목록'
  },
  hyundai_capital: {
    schema: z.object({}).passthrough(),
    url: '/promotions/hyundai_capital', // '현대캐피탈'
  },
  input_displacement: {
    schema: z.object({}).passthrough(),
    url: '/input_displacement', // '배기량 입력'
  },
  inspection: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId', // '진단 조회'
  },
  inspection_memo: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/memo', // '진단사 - 진단 관련 메모'
  },
  inspection_reschedule: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/reschedule', // '진단사 - 진단 시간 변경'
  },
  inspection_cancel: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/cancel', // '진단사 - 진단 취소'
  },
  inspection_attach_dealer_proposal: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/attach_dealer_proposal', // '진단 예약 후 딜러 제안가 입력하는 페이지'
  },
  inspection_guide: {
    schema: z
      .object({
        carNo: zDecoder.str(),
        ownerName: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspection_guide', // '사전 진단 혜택'
  },
  warranty: {
    schema: z.object({
      articleId: zDecoder.str(),
    }),
    url: '/articles/:articleId/warranty', // '진단보증 패키지 > 보험가입확인서'
  },
  register_warranty: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/register_warranty', // '진단보증 패키지 > 가입결제',
  },
  warranty_privacy_consent: {
    schema: z.object({}).passthrough(),
    url: '/warranty_privacy_consent', // '진단보증 패키지 > 개인정보 제공 동의'
  },
  warranty_terms_agreement: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/warranty_terms_agreement', // '진단보증 패키지 > 약관 동의'
  },
  warranty_coverage_exclusion: {
    schema: z.object({}).passthrough(),
    url: '/warranty_coverage_exclusion', // '진단보증 패키지 > 보증 제외 항목'
  },
  warranty_guide: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/warranty_guide', // '진단보증 패키지 > 가이드'
  },
  warranty_exclusion_guide: {
    schema: z.object({}).passthrough(),
    url: '/warranty_exclusion_guide', // '[DEPRECATED] 보증 안내 - 보장 제외 대상'
  },
  main: {
    schema: z.object({
      initialTab: zDecoder.enum(['buyer', 'seller'] as const).optional(),
    }),
    url: '/', // '홈 피드'
  },
  my_sales_history: {
    schema: z.object({}).passthrough(),
    url: '/my_sales_history', // '판매내역'
  },
  no_accident: {
    schema: z.object({}).passthrough(),
    url: '/faqs/no_accident', // '무사고 정의'
  },
  notification: {
    schema: z.object({}).passthrough(),
    url: '/notification', // '새 글 알림 목록'
  },
  notification_articles: {
    schema: z
      .object({
        title: zDecoder.str(),
        notificationId: zDecoder.str(),
      })
      .passthrough(),
    url: '/notifications/:notificationId/articles', // '새글 알림 필터 - 게시글 목록'
  },
  pullup_article: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        state: z.enum(['confetti', 'payment', 'suggest_promotion'] as const).optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/pull_up', // '끌올'
  },
  reserve_auction_inspection: {
    schema: z
      .object({
        step: zDecoder.nativeEnum(ReservationStep).optional(),
        auctionTradeId: zDecoder.str().optional(),
        carNo: zDecoder.str().optional(),
        ownerName: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/reserve', // '글 쓰기 - 판매자 사전 진단 - 정보 입력'
  },
  reserve_inspection_again: {
    schema: z
      .object({
        step: zDecoder.nativeEnum(ReservationStep).optional(),
        auctionTradeId: zDecoder.str().optional(),
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/reserve_again', // '진단 상세 - 진단 변경하기 페이지'
  },
  sales_history: {
    schema: z.object({}).passthrough(),
    url: '/sales_history', // '판매내역 (중고거래 통합, deprecated)'
  },
  search: {
    schema: z
      .object({
        companyIds: zDecoder.strArray().optional(),
        seriesIds: zDecoder.strArray().optional(),
        subseriesIds: zDecoder.strArray().optional(),
        keyword: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/search', // '검색'
  },
  search_result: {
    schema: z
      .object({
        companyOrigin: zDecoder.nativeEnum(CompanyOrigin).optional(),
        companyIds: zDecoder.strArray().optional(),
        seriesIds: zDecoder.strArray().optional(),
        subseriesIds: zDecoder.strArray().optional(),
        keyword: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/search_result', // '검색 결과'
  },
  select_company: {
    schema: z.object({}).passthrough(),
    url: '/select_company', // '제조사 선택'
  },
  select_grade: {
    schema: z
      .object({
        subseriesId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_grade/:subseriesId', // '등급 선택'
  },
  select_series: {
    schema: z
      .object({
        companyId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_series/:companyId', // '시리즈 선택'
  },
  select_subgrade: {
    schema: z
      .object({
        gradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_subgrade/:gradeId', // '세부등급 선택'
  },
  select_subseries: {
    schema: z
      .object({
        seriesId: zDecoder.str(),
      })
      .passthrough(),
    url: '/select_subseries/:seriesId', // '세부시리즈 선택'
  },
  submerged_car: {
    schema: z.object({}).passthrough(),
    url: '/faqs/submerged_car', // '침수차 가이드'
  },
  subseries_metadata: {
    schema: z
      .object({
        subseriesId: zDecoder.str(),
      })
      .passthrough(),
    url: '/subseries/:subseriesId', // '세부시리즈 메타데이터'
  },
  subseries_transaction_history: {
    schema: z
      .object({
        subseriesId: zDecoder.str(),
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/subseries/:subseriesId/transaction_history', // '거래 내역 목록'
  },
  trade_guide: {
    schema: z
      .object({
        scrollTarget: zDecoder.enum(['check', 'search', 'register'] as const).optional(),
      })
      .passthrough(),
    url: '/faqs/trade_guide', // '직거래 가이드'
  },
  trade_review: {
    schema: z
      .object({
        tradeReviewId: zDecoder.str(),
        action: zDecoder.enum(['check_status'] as const).optional(),
      })
      .passthrough(),
    url: '/trade_reviews/:tradeReviewId', // '거래후기'
  },
  traded_cars: {
    schema: z.object({}).passthrough(),
    url: '/traded_cars', // '거래완료 차량 목록'
  },
  write_article: {
    schema: z
      .object({
        step: zDecoder.nativeEnum(WriteStep).optional(),
      })
      .passthrough(),
    url: '/articles/new', // '글 쓰기'
  },
  write_trade_review: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        receiverId: zDecoder.str(),
        rating: zDecoder.enum(['BAD', 'EXCELLENT', 'GOOD', 'NORMAL'] as const).optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/write_trade_review/:receiverId', // '거래후기 작성'
  },
  liked_articles: {
    schema: z.object({}).passthrough(),
    url: '/liked_articles', // '관심 중고차'
  },
  register_mechanic: {
    schema: z.object({}).passthrough(),
    url: '/register_mechanic', // '정비사 등록하기'
  },
  register_mechanic_input_regions: {
    schema: z
      .object({
        initialRegions: zDecoder.strArray().optional(),
      })
      .passthrough(),
    url: '/register_mechanic/input_regions', // '정비사 등록하기 - 지역 입력'
  },
  input_addresses: {
    schema: z.object({}).passthrough(),
    url: '/input_addresses', // '정비사 등록하기 - 주소 입력'
  },
  register_mechanic_success: {
    schema: z.object({}).passthrough(),
    url: '/register_mechanic/success', // '정비사 등록하기 - 등록 성공'
  },
  my_inspections: {
    schema: z
      .object({
        tab: zDecoder.enum(['approved', 'completed', 'requested'] as const).optional(),
      })
      .passthrough(),
    url: '/my_inspections', // '정비사 - 내 예약 관리'
  },
  my_inspections_approve_inspection: {
    schema: z
      .object({ inspectionRequestId: zDecoder.str(), dateStr: zDecoder.str() })
      .passthrough(),
    url: '/my_inspections/:inspectionRequestId/approve_inspection', // '진단 예약 확정 바텀 시트'
  },
  my_inspections_contact_customer: {
    schema: z.object({ inspectionId: zDecoder.str() }).passthrough(),
    url: '/my_inspections/:inspectionId/contact_customer', // '고객 연락 바텀 시트'
  },
  write_report_content: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/content', // '차량 진단 작성'
  },
  write_report_item: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        category: zDecoder.str(),
        group: zDecoder.str(),
        invalidPart: zDecoder.str().optional(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/item', // '차량 진단 작성 - 진단 항목 작성'
  },
  write_report_obd_code: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/obd_code', // '차량 진단 작성 - 진단 항목 작성'
  },
  write_report_confirm: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/confirm', // '차량 진단 작성 - 확인'
  },
  write_report_reject: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/reject', // '차량 진단 작성 - 거절'
  },
  write_report_car_data: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/car_data', // '차량 진단 작성 - 차량 데이터 변경'
  },
  write_report_options: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
        tab: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/options', // '차량 진단 작성 - 차량 옵션 입력'
  },
  write_report_base_info: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/base_info', // '차량 진단 작성 - 차량 옵션 입력'
  },
  write_report: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report', // '차량 진단 작성 - 진입점'
  },
  // TODO: 적절한 이름 찾아보기
  write_report_view: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        reportId: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/view', // '차량 진단 작성 - 작성 후 상세보기 (진단사 본인진단 내용 확인용)',
  },
  write_report_approval: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_report/approval', // '차량 진단 - 진단 리포트 내용 승인(검수자용)',
  },
  inspection_report: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        selectedTirePart: zDecoder.enum(['front', 'rear'] as const).optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/report', // '차량 상태 - 사용자 향 차량 진단 보고서'
  },
  inspection_report_images: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        scrollTarget: zDecoder.enum(['interior', 'exterior'] as const).optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/report/images', // '차량 상태 - 사용자 향 차량 진단 보고서 - 이미지'
  },
  moon_jin_pyo: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/moon_jin_pyo', // '진단 - 문진표'
  },
  write_mechanic_review: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        auctionTradeId: zDecoder.str().optional(),
        initialRating: zDecoder.enum(mechanicReviewRatingTypes).optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/write_mechanic_review', // '진단사 리뷰 작성'
  },
  mechanic_my_profile: {
    schema: z.object({}).passthrough(),
    url: '/mechanic/my_profile', // '진단사 - 내 프로필'
  },
  mechanic_time_table: {
    schema: z
      .object({
        dayIndex: zDecoder.str(),
      })
      .passthrough(),
    url: '/mechanic/my_time_table', // '진단사 - 타임 테이블 편집'
  },
  payment_smoke_test: {
    schema: z
      .object({
        title: zDecoder.str(),
        price: zDecoder.str(),
        actionLabel: zDecoder.str().optional(),
        calloutText: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/payment_smoke_test', // '결제 스모크 테스트'
  },
  inspection_faqs: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/faqs', // '당근 진단 FAQ'
  },
  inspections_for_review: {
    schema: z.object({}).passthrough(),
    url: '/inspections_for_review', // '진단 리포트 목록 - 검수용'
  },

  inspection_finalize_article: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/finalize_article', // '진단 - 가격/추가 정보 입력 및 매물 등록'
  },
  inspection_estimation_report: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/estimation_report', // '진단 - 가치 평가 리포트'
  },
  article_safe_trade: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
        nextEvent: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId', // '게시글 - 안전 직거래'
  },
  article_safe_trade_register_account: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/register_account', // '게시글 - 안전 직거래 -계좌 입력',
  },
  article_safe_trade_buyer_complete_payment: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_complete_payment', // '게시글 - 안전직거래 - 구매자 가상계좌 발급 완료',
  },
  article_safe_trade_buyer_confirm_trade: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_complete_trade', // '게시글 - 안전 직거래- 구매자 거래 완료',
  },
  article_safe_trade_seller_cancel_trade: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/seller_cancel_trade', // '게시글 - 안전 직거래- 판매자 거래 취소',
  },
  article_safe_trade_buyer_input_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_input_price', // '게시글 - 안전 직거래 -구매자의 차량 가액 입력',
  },
  article_safe_trade_buyer_confirm_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
        price: zDecoder.num(), // 만원 단위
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/buyer_confirm_price', // '게시글 - 안전 직거래- 구매자의 가격 확인 및 계좌 입금',
  },
  article_safe_trade_seller_confirm_price: {
    schema: z
      .object({
        articleId: zDecoder.str(),
        safeTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade/:safeTradeId/seller_confirm_price', // '게시글 - 안전 직거래- 판매자 가격 확인',
  },
  safe_trade_guide: {
    schema: z
      .object({
        articleId: zDecoder.str(),
      })
      .passthrough(),
    url: '/articles/:articleId/safe_trade_guide', // '게시글 - 안전 직거래 - 판매자 가격 확인'
  },
  agreement: {
    schema: z
      .object({
        agreementId: zDecoder.nativeEnum(AgreementId),
      })
      .passthrough(),
    url: '/agreement/:agreementId', // '약관 동의 (agreementId에 따라 달라져요)'
  },
  not_found: {
    schema: z.object({}).passthrough(),
    url: '*', // '404 페이지'
  },
  inspection_reply: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        step: zDecoder.nativeEnum(ReservationStep).optional(),
        actionType: zDecoder.enum(['approve', 'reject'] as const).optional(),
      })
      .passthrough(),
    url: '/inspections/:inspectionId/reply', // '글 쓰기 - 판매자 사전 진단 - 정보 입력'
  },
  auction_trade_progress: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/progress', // 경매 진행 상황
  },
  auction_trade_seizure_or_mortgage: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/seizure_or_mortgage', // 압류 / 저당 있을 경우
  },
  auction_trade_input_pros: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/input_pros', // 경매 상세 - 내 차 장점 입력
  },
  auction_trade_write_trade_review: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
        initialRating: zDecoder.num().optional(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/write_trade_review', // 경매 상세 - 후기 입력
  },
  auction_trade_input_cancelation_reason: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/input_cancelation_reason', // 경매 상세 - 판매 취소 사유 입력
  },
  auction_trade_input_car_registration: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/input_car_registration', // 경매 상세 - 자동차 등록증 사진 입력
  },
  auction_trade_insurance_documents: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/insurance_documents', // 경매 상세 - 거래 후 보험 관련 서류
  },
  auction_trade_selling_documents: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
        [SellingDocumentQuestion.ARE_YOU_BUY_A_CAR_FOR_BUSINESS]: zDecoder
          .nativeEnum(SellingDocumentQuestionAnswer)
          .optional(),
        [SellingDocumentQuestion.ARE_YOU_HAVE_BUSINESS_LICENSE]: zDecoder
          .nativeEnum(SellingDocumentQuestionAnswer)
          .optional(),
        [SellingDocumentQuestion.CHOICE_BOOK_TYPE]: zDecoder
          .nativeEnum(SellingDocumentQuestionAnswer)
          .optional(),
        [SellingDocumentQuestion.CHOICE_INDIVIDUAL_LICENSE_TYPE]: zDecoder
          .nativeEnum(SellingDocumentQuestionAnswer)
          .optional(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/selling_documents', // 경매 상세 - 판매에 필요한 서류
  },
  auction_trade_selling_documents_confirm: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
        sellerDocumentType: zDecoder.nativeEnum(AuctionSellerDocumentType).optional(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/selling_documents_confirm', // 경매 상세 - 판매에 필요한 서류 확인
  },
  auction_trade_reserve_delivery: {
    schema: z
      .object({
        auctionTradeId: zDecoder.str(),
        step: zDecoder.nativeEnum(DeliveryStep).optional(),
      })
      .passthrough(),
    url: '/auction_trades/:auctionTradeId/reserve_delivery', // '경매 - 탁송 예약'
  },
  auction_trade_report: {
    schema: z.object({ auctionTradeId: zDecoder.str() }).passthrough(),
    url: '/auction_trades/:auctionTradeId/inspection_report', // '경매 - 진단 리포트'
  },
  auction_trade_report_inspection_report_images: {
    schema: z.object({ inspectionId: zDecoder.str() }).passthrough(),
    url: '/auction_trades/inspections/:inspectionId/report/images', // '경매 - 진단 리포트 - 이미지'
  },
  report_problem: {
    schema: z
      .object({
        title: zDecoder.str(),
        problem: zDecoder.str().optional(),
        comment: zDecoder.str().optional(),
        imgs: zDecoder.strArray().optional(),
      })
      .passthrough(),
    url: '/report_problem', // '문제 항목 바텀시트'
  },
  accident_guide: {
    schema: z
      .object({
        accidentType: zDecoder.nativeEnum(AccidentType).optional(),
      })
      .passthrough(),
    url: '/accident_guide', // '사고 진단 가이드 바텀시트'
  },
  auction_trade_car_options: {
    schema: z.object({ auctionTradeId: zDecoder.str() }).passthrough(),
    url: '/auction_trades/:auctionTradeId/car_options', // '경매 - 차량 옵션'
  },
  input_phone: {
    schema: z
      .object({
        initialPhone: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/input_phone', // '정비사 등록하기 - 주소 입력'
  },
  alert_dialog: {
    schema: z
      .object({
        description: zDecoder.str(),
        primaryActionLabel: zDecoder.str(),
        secondaryActionLabel: zDecoder.str().optional(),
        secondaryActionIntent: zDecoder.enum(['alternative', 'neutral', 'nonpreferred']).optional(),
        title: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/alert_dialog', // '다이얼로그'
  },
  alert_bottom_sheet: {
    schema: z
      .object({
        description: zDecoder.str(),
        primaryActionLabel: zDecoder.str(),
        secondaryActionLabel: zDecoder.str().optional(),
        secondaryActionIntent: zDecoder.enum(['alternative', 'neutral', 'nonpreferred']).optional(),
        title: zDecoder.str(),
      })
      .passthrough(),
    url: '/alert_bottom_sheet', // '다이얼로그'
  },
  seller_first_user_agreement: {
    schema: z
      .object({
        tradeType: zDecoder.enum(['auction', 'direct']),
      })
      .passthrough(),
    url: '/seller_first_user_agreement', // '판매자 최초 유저 동의'
  },
  bank_maintenance_time_guide: {
    schema: z.object({ auctionTradeId: zDecoder.str() }).passthrough(),
    url: '/bank_maintenance_time_guide', // '은행 정기 점검 시간 가이드'
  },
  brand_asset_feedback: {
    schema: z
      .object({
        assetName: zDecoder.str(),
        assetClassName: zDecoder.str(),
        texts: zDecoder.strArray(),
        textDuration: zDecoder.num().optional(),
        activityName: zDecoder.str().optional(),
      })
      .passthrough(),
    url: '/brand_asset_feedback',
  },
  forbidden_foreigner: {
    schema: z.object({}).passthrough(),
    url: '/forbidden_foreigner', // '외국인 제한'
  },
  start_inspection_confirm: {
    schema: z
      .object({
        inspectionId: zDecoder.str(),
        inspectionType: zDecoder.enum(['PROXY_INSPECTION', 'AUCTION_INSPECTION', 'PRE_INSPECTION']),
        description: zDecoder.str(),
      })
      .passthrough(),
    url: '/start_inspection_confirm', // '진단 시작 확인'
  },
  terms_and_policy: {
    schema: z.object({}).passthrough(),
    url: '/terms_and_policy', // '이용약관 및 운영정책'
  },
};

export type ActivityName = keyof typeof activityDescription;

export type ActivityParams<TActivityName extends ActivityName> = Prettify<
  z.output<(typeof activityDescription)[TActivityName]['schema']>
>;
