import type { ReportItemState } from '@daangn/car-types';

import { consumableGroups } from '@daangn/car-types';
import { getIn } from '@daangn/car-utils/misc';
import { nonNullish } from '@daangn/car-utils/typeGuard';

type ReportItemType = 'empty' | 'none' | 'selected' | 'selectedWithInformation';
const initializeResult = (): Record<'total' | ReportItemType, number> => ({
  empty: 0,
  none: 0,
  selected: 0,
  selectedWithInformation: 0,
  total: 0,
});

export const getReportItemStateType = (state: ReportItemState): ReportItemType => {
  if (state.selections.includes('None')) {
    return 'none';
  }
  if (!state.selections.filter((v) => v !== 'NoIssues').length) {
    return 'empty';
  }
  if (state.comment || state.imgs?.length) {
    return 'selectedWithInformation';
  }
  return 'selected';
};

export const getPartsIssueCount = (parts: (ReportItemState | undefined)[]) => {
  const result = initializeResult();

  parts.filter(nonNullish).forEach((part) => {
    result[getReportItemStateType(part)]++;
    result.total++;
  });

  return result;
};

export const getGroupIssueCount = (group?: Record<string, ReportItemState | undefined>) => {
  if (!group) {
    return initializeResult();
  }
  return getPartsIssueCount(Object.values(group));
};

export const getCategoryIssueCount = (
  category: Record<string, Record<string, ReportItemState | undefined> | undefined>
) => {
  const result = initializeResult();

  if (!category) {
    return result;
  }

  Object.values(category).forEach((group) => {
    const groupResult = getGroupIssueCount(group);
    Object.keys(result).forEach((key) => {
      result[key as ReportItemType] += groupResult[key as ReportItemType];
    });
  });

  return result;
};

export const getCategoryIssueCountWithoutConsumables = (
  report: {
    [category: string]: {
      [group: string]: { [part: string]: ReportItemState | undefined } | undefined;
    };
  },
  categoryKey: string
) => {
  const category = getIn(report, categoryKey);

  if (!category) {
    return initializeResult();
  }
  const result = getCategoryIssueCount(category);

  Object.keys(result).forEach((key) => {
    consumableGroups
      .filter((groupKey) => groupKey.startsWith(`${categoryKey}.`))
      .forEach((groupKey) => {
        const group = getIn(report, groupKey);
        const groupResult = getGroupIssueCount(group);
        result[key as ReportItemType] -= groupResult[key as ReportItemType];
      });
  });

  return result;
};
