import { z } from 'zod';

import { MechanicReviewFeedbackChoice } from '@/pages/WriteMechanicReview/types';

export const mechanicReviewRatingTypes = ['BAD', 'NORMAL', 'EXCELLENT'] as const;

export const MechanicReviewSchema = z.object({
  rating: z.enum(mechanicReviewRatingTypes),
  choices: z
    .array(z.nativeEnum(MechanicReviewFeedbackChoice))
    .min(1, { message: '항목을 선택해 주세요.' }),
  message: z.string().optional(),
});

export type MechanicReviewData = z.input<typeof MechanicReviewSchema>;
