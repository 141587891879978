import type {
  MechanicReportCategory,
  MechanicReportPath,
  ReportItemState,
  ReportItemStatePath,
} from '@daangn/car-types';

import { selectionListTable } from '@daangn/car-types';
import { objectEntries } from '@toss/utils';

export const hasPartIssue = (part: ReportItemState | undefined, partPath: ReportItemStatePath) => {
  if (!part) {
    return false;
  }
  const [availableSelectionList, allowedSelectionList] = selectionListTable[partPath];
  const hasIssue = part.selections.some(
    (v) => availableSelectionList.includes(v) && !allowedSelectionList.includes(v)
  );
  return hasIssue;
};

export const hasGroupIssue = (
  group: Record<string, ReportItemState | undefined>,
  groupPath: MechanicReportPath
) => {
  const hasIssue = objectEntries(group).some(([partPath, part]) => {
    if (!part) {
      return false;
    }
    const fullPath = `${groupPath}.${partPath}` as ReportItemStatePath;
    return hasPartIssue(part, fullPath);
  });
  return hasIssue;
};

export const hasCategoryIssue = (
  category: Record<string, Record<string, ReportItemState | undefined> | undefined>,
  categoryPath: MechanicReportCategory,
  excludeGroupPaths: MechanicReportPath[] = []
) => {
  const hasIssue = objectEntries(category).some(([groupPath, group]) => {
    if (!group) {
      return false;
    }
    const path = `${categoryPath}.${groupPath}` as MechanicReportPath;
    return hasGroupIssue(group, path) && !excludeGroupPaths.includes(path);
  });
  return hasIssue;
};
